body {
  height: 100vh;
}

#root {
  height: 100%;
}

.collapse {
  margin-top: 0 !important;
}

code {
  white-space: pre-wrap;
}

body.chakra-ui-dark input,
body.chakra-ui-dark textarea,
body.chakra-ui-dark select {
  background-color: transparent !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.ai-card-bg {
  background-color: #4158d0;
  background-image: linear-gradient(
    45deg,
    var(--chakra-colors-purple-500) 0%,
    var(--chakra-colors-brand-base) 26%,
    var(--chakra-colors-brand-base) 46%,
    var(--chakra-colors-purple-300) 100%
  );
}

/*starts: ckeditor experiments*/
:root {
  --ck-z-default: 2000; /* ckeditor floating components higher than chakra floating components*/
  --ck-color-base-border: hsl(214, 32%, 91%) !important;
  --ck-color-base-focus: hsl(331, 74%, 51%) !important;
  --ck-color-base-active: hsl(331, 74%, 51%) !important;
  --ck-color-base-active-focus: hsl(331, 74%, 51%) !important;
  --ck-color-base-text: hsl(215, 19%, 35%) !important;

  --ck-border-radius: 4px !important;
  --ck-spacing-unit: 0.5em !important;
  --ck-font-size-base: 14px !important;
  --ck-font-face: Inter, Helvetica, Arial, Tahoma, Verdana, Sans-Serif !important;

  /**
	 * The geometry of the of focused element's outer shadow.
	 */
  --ck-focus-outer-shadow-geometry: 0 0 0 2px !important;

  /* -- Generic colors ------------------------------------------------------------------------ */

  --ck-color-focus-border: hsl(331, 74%, 51%) !important;
  --ck-color-focus-outer-shadow: hsl(331, 74%, 90%) !important;

  /* -- Buttons ------------------------------------------------------------------------------- */

  --ck-color-button-on-color: hsl(331, 70%, 37%) !important;
}

input,
textarea,
select {
  background-color: white !important;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  border-color: var(--chakra-colors-brand-base) !important;
  box-shadow: 0 0 0 1px var(--chakra-colors-brand-50) !important;
}

body.chakra-ui-dark input:focus-visible,
body.chakra-ui-dark textarea:focus-visible,
body.chakra-ui-dark select:focus-visible {
  border-color: var(--chakra-colors-brand-base) !important;
  box-shadow: 0 0 0 1px var(--chakra-colors-brand-base) !important;
}

/*Customization for the CKEditor that renders on for the Metadata Content: */

.ck-focused {
  border: 1px solid var(--chakra-colors-brand-base) !important;
  background-color: var(--chakra-colors-white) !important;
}

body.chakra-ui-dark .ck-focused {
  background-color: var(--chakra-colors-neutral-850) !important;
}

body.chakra-ui-dark .ck.ck-toolbar {
  background-color: transparent !important;
}

[data-theme='dark'] {
  --ck-color-base-border: var(--chakra-colors-neutral-700) !important;
}

[data-theme='dark'] .ck-revision-history-ui__changes-navigation__wrapper {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] [data-cke-tooltip-text='Restore this revision'] {
  background-color: var(--chakra-colors-brandSecondary-950) !important;
  color: var(--chakra-colors-brandSecondary-25) !important;
  font-weight: bold !important;
  box-shadow: none !important;
}

[data-theme='dark'] .ck-revision-history-sidebar__revision {
  background-color: transparent !important;
  border-color: var(--chakra-colors-neutral-700) !important;
}

[data-theme='dark'] input.ck.ck-input-text {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark']
  .ck-content
  .ck-suggestion-marker-insertion.ck-suggestion-marker {
  background: var(--chakra-colors-brand-800);
  border: 0 !important;
  padding: 2px 4px;
}

[data-theme='dark'] .ck-suggestion-marker-split {
  background-color: var(--chakra-colors-green-800) !important;
}

[data-theme='dark'] .ck-revision-history-sidebar__revision__controls-wrapper {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] .ck-revision-history-sidebar__revision__date {
  background-color: var(--chakra-colors-neutral-800) !important;
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark']
  .ck-revision-history-sidebar__revision__actions
  .ck.ck-dropdown__panel {
  background-color: var(--chakra-colors-neutral-800) !important;
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark']
  .ck-revision-history-sidebar__revision__actions
  .ck.ck-list {
  background-color: var(--chakra-colors-neutral-800) !important;
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] [data-cke-tooltip-text='Show more items'] {
  background-color: var(--chakra-colors-neutral-800) !important;
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark']
  .ck-revision-history-sidebar__revision__actions
  .ck.ck-button.ck-list-item-button {
  background-color: var(--chakra-colors-neutral-800) !important;
  color: var(--chakra-colors-neutral-100) !important;
  box-shadow: none !important;
}

[data-theme='dark']
  .ck-revision-history-ui__changes-navigation__navigation
  > .ck.ck-button:not(.ck-disabled):hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

[data-theme='dark'] .ck-revision-change__add {
  background-color: var(--chakra-colors-neutral-800) !important;
}

[data-theme='dark'] .ck-revision-change__add > span {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] .ck-revision-change.ck-revision-change__remove {
  background-color: var(--chakra-colors-neutral-800) !important;
}

[data-theme='dark'] .ck-revision-change.ck-revision-change__remove > span {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] .ck-revision-change__label {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] .ck-revision-history-ui__changes-navigation__label {
  color: var(--chakra-colors-neutral-100) !important;
}

[data-theme='dark'] .ck-revision-history-ui__back-to-editing {
  background-color: var(--chakra-colors-neutral-800) !important; /* Dark mode */
  color: var(--chakra-colors-neutral-100) !important; /* Dark mode */
}

[data-theme='dark']
  .ck.ck-revision-history-sidebar__time-period:not(.ck-hidden)
  + .ck-revision-history-sidebar__time-period {
  margin-top: 0 !important;
}

.with-height > .ck.ck-editor__editable {
  min-height: 100px;
}

.max-height-300 > .ck.ck-editor__editable {
  max-height: 300px;
}

.ck.ck-editor__editable {
  padding: 0.25rem 0.25rem 0.5rem !important;
  outline: 1px solid transparent;
  transition: outline 0.2s ease-out;
  background-color: var(--chakra-colors-white);
}

body.chakra-ui-dark .ck.ck-editor__editable {
  background-color: transparent;
}

/* .show-outline > .ck.ck-editor__editable {
  outline: 1px solid var(--chakra-colors-neutral-200);
}

body.chakra-ui-dark .show-outline > .ck.ck-editor__editable {
  outline: 1px solid var(--chakra-colors-chakra-border-color);
}

.ck.ck-editor__editable:hover {
  outline: 1px solid var(--chakra-colors-neutral-200);
} */

body.chakra-ui-dark .ck.ck-editor__editable:hover {
  outline: 1px solid var(--chakra-colors-neutral-700);
}

.ck.ck-toolbar > .ck-toolbar__items {
  justify-content: center !important;
}

.ck.ck-editor__main > .ck-blurred {
  background: transparent !important;
  border: none;
  /*caret-color: transparent;*/
}

.ck.ck-editor__main > .ck-column-resize_disabled.ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0 !important;
}

.ck.ck-editor__editable_inline > :last-child {
  margin-bottom: 0 !important;
}

.ck.ck-block-toolbar-button {
  transform: translateX(-10px);
}

.commentsOnly-true .ck.ck-editor__editable:hover,
.ai-preview .ck.ck-editor__editable:hover {
  outline: none !important;
  background-color: transparent !important;
  cursor: default !important;
  /*border: none !important;*/
  box-shadow: none !important;
  caret-color: transparent !important;
}

.with-read-only-style > .ck.ck-editor__editable,
.ai-preview .ck.ck-editor__editable {
  background-color: rgba(0, 0, 0, 0.05);
}

.with-read-only-style > .ck.ck-editor__editable:hover,
.ai-preview .ck.ck-editor__editable:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  cursor: not-allowed !important;
}

.ck-as-form-field > .ck-blurred {
  border: 1px var(--chakra-colors-chakra-border-color) solid !important;
}

.ck-content p,
.ck-content h1,
.ck-content h2,
.ck-content h3,
.ck-content h4,
.ck-content h5 {
  margin: 10px 0;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  /*list-style-type: inherit;*/
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 2.666em;
}

.ck.ck-content ul ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 1.666em;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  /* list-style-type: decimal; */
}

.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 2.666em;
}

.ck.ck-content ol ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 1.666em;
}

.ck-content a {
  color: var(--chakra-colors-brand-600);
  text-decoration: underline;
}

.ck-content a:hover {
  text-decoration: double underline;
}

.ck-comment-marker {
  background-color: var(--chakra-colors-brand-50) !important;
  border-radius: 0.2rem !important;
}

body.chakra-ui-dark .ck-comment-marker {
  background-color: var(--chakra-colors-brand-800) !important;
}

input.ck.ck-input-text {
  color: var(--chakra-colors-neutral-850) !important;
  background-color: transparent !important;
}

.ck-revision-history-sidebar__revision {
  border-left-width: 1px !important;
}

.ck.ck-revision-history-sidebar__revision-wrapper
  .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
  background-color: transparent;
  border-left: 4px solid var(--chakra-colors-brand-base);
  border-radius: 0.2rem;
  color: var(--chakra-colors-neutral-850);
}

.ck-revision-history-sidebar__revision-wrapper {
  margin-top: 4px !important;
}

.ck-revision-history-ui__back-to-editing {
  background-color: var(--chakra-colors-neutral-100) !important;
  color: var(--chakra-colors-neutral-900) !important;
  font-weight: bold !important;
  margin-right: 20px !important;
}

.ck.ck-revision-history-sidebar__revision-author .ck-icon {
  top: 6px !important;
}

.ck-revision-history-sidebar__header {
  border: 0 !important;
}

.revision-viewer-container {
  display: flex !important;
}

.sidebar-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.ck-revision-history-sidebar__revision__date {
  width: fit-content !important;
  padding: 4px 6px !important;
  background-color: var(--chakra-colors-neutral-100) !important;
  color: var(--chakra-colors-neutral-850) !important;
  border-radius: 4px !important;
}

.revision-viewer-container > .ck-editor {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  height: 100% !important;
  width: 70% !important;
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
}

.ck-sticky-panel__content {
  border: 0 !important;
}

.ck-editor__main {
  height: 100%;
}

.ck-content {
  height: 100%;
}

.ck-content .ck-suggestion-marker-insertion.ck-suggestion-marker {
  background: var(--chakra-colors-brand-50);
  border: 0 !important;
  padding: 2px 4px;
}

.ck.ck-revision-history-sidebar__revision-wrapper
  .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected {
  border: 1px solid var(--chakra-colors-brand-400) !important;
  border-left-width: 4px !important;
}

.ck.ck-revision-history-sidebar__revision-wrapper
  .ck-revision-history-sidebar__revision {
  border: 1px solid var(--chakra-colors-neutral-200);
  border-left-width: 4px;
}

[data-cke-tooltip-text='Restore this revision'] {
  background-color: #356169 !important;
  color: var(--chakra-colors-neutral-100) !important;
  font-weight: bold !important;
}

/*}*/
.ck.ck-revision-history-sidebar__revision-wrapper
  .ck-revision-history-sidebar__revision {
  box-shadow: none;
}

.ck-revision-history-sidebar__time-period__label {
  display: none !important;
}

.ck-content .ck-suggestion-marker-deletion {
  border: 0 !important;
  padding: 2px 4px;
}

.ck-suggestion-marker-split {
  background-color: var(--chakra-colors-green-50) !important;
}

.ck-suggestion-marker-merge {
  background-color: var(--chakra-colors-red-50) !important;
}

.ck-content .ck-suggestion-marker-merge:after {
  border: 0 !important;
  padding: 2px 1px;
}

.ck-content .ck-suggestion-marker-split:after {
  border: 0 !important;
  padding: 2px 1px;
}

.ck-content ::selection {
  background: var(--chakra-colors-brand-50) !important;
}
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input[checked]:before {
  background: var(--chakra-colors-brand-base);
  border-color: var(--chakra-colors-brand-500);
}

.ck .ck-revision-change.ck-revision-change__add {
  border-left-color: var(--chakra-colors-brand-500);
}

.ck.ck-balloon-panel.ck-balloon-panel_arrow_nw:before {
  display: none !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_arrow_nw:after {
  display: none !important;
}

/*ends: ckeditor experiments*/

.narrow {
  width: 0;
  overflow: hidden;
}

.narrow:has(.ck-sidebar-item) {
  width: var(--chakra-sizes-10);
}

.link-finding-checkbox .chakra-checkbox__label {
  width: 100%;
}

body.chakra-ui-dark .custom-field-edit-form .chakra-input,
body.chakra-ui-dark .custom-field-edit-form .chakra-textarea,
body.chakra-ui-dark .custom-field-edit-form .chakra-select {
  background-color: var(--chakra-colors-neutral-850);
}

.custom-field-edit-form .chakra-input,
.custom-field-edit-form .chakra-textarea,
.custom-field-edit-form .chakra-select {
  background-color: white;
}

.custom-field-edit-form .chakra-input:focus-visible,
.custom-field-edit-form .chakra-textarea:focus-visible,
.custom-field-edit-form .chakra-select:focus-visible {
  border-color: var(--chakra-colors-brand-base);
  box-shadow: 0 0 0 1px var(--chakra-colors-brand-50);
}

.chakra-select__icon-wrapper {
  z-index: 2;
}

.chakra-form-control:has(.chakra-input[type='datetime-local']) > * {
  display: block;
}

.code-snippet {
  background: transparent;
}

.ck-button_generateWithAI > svg {
  width: 65px !important;
}

/* .complianceSummaryItem:first-child {
  align-items: flex-end;
  text-align: right;
  padding-right: 1vw;
}
.complianceSummaryItem:last-child {
  align-items: flex-start;
  text-align: left;
  padding-left: 1vw;
} */
.queryBuilder {
  font-size: small;
}

.queryBuilder select {
  padding: 0.2rem;
  border-radius: 0.2rem;
}

.queryBuilder select:focus-visible {
  outline: var(--chakra-colors-brand-base);
  box-shadow: 0 0 0 1px var(--chakra-colors-brand-base);
}

.queryBuilder .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
.queryBuilder .ruleGroup-addGroup + button.ruleGroup-lock,
.queryBuilder .ruleGroup-addGroup + button.ruleGroup-remove,
.queryBuilder .rule-operators + button.rule-cloneRule,
.queryBuilder .rule-operators + button.rule-lock,
.queryBuilder .rule-operators + button.rule-remove,
.queryBuilder .rule-value + button.rule-cloneRule,
.queryBuilder .rule-value + button.rule-lock,
.queryBuilder .rule-value + button.rule-remove {
  margin-left: auto !important;
}

.queryBuilder .ruleGroup {
  background-color: #cacccd22;
  border: 1px solid var(--chakra-colors-chakra-border-color);
}

body.chakra-ui-dark .queryBuilder button.rule-remove,
body.chakra-ui-dark .queryBuilder button.ruleGroup-addRule,
body.chakra-ui-dark .queryBuilder button.ruleGroup-remove,
body.chakra-ui-dark .queryBuilder button.ruleGroup-addGroup {
  border: 1px solid var(--chakra-colors-neutral-700);
  background-color: var(--chakra-colors-neutral-800);
}

body.chakra-ui-dark .queryBuilder button.rule-remove:hover,
body.chakra-ui-dark .queryBuilder button.ruleGroup-addRule:hover,
body.chakra-ui-dark .queryBuilder button.ruleGroup-remove:hover,
body.chakra-ui-dark .queryBuilder button.ruleGroup-addGroup:hover {
  background-color: var(--chakra-colors-neutral-600);
}

.queryBuilder button.rule-remove,
.queryBuilder button.ruleGroup-remove {
  padding: 0 0.5rem;
}

.queryBuilder select.rule-fields,
.queryBuilder select.rule-operators,
.queryBuilder select.ruleGroup-combinators,
.queryBuilder select.rule-value,
.queryBuilder input.rule-value {
  padding: 0.3rem;
  border: 1px solid var(--chakra-colors-chakra-border-color);
  border-radius: 4px;
  max-width: 10rem;
}

.queryBuilder input.rule-value:focus-visible {
  box-shadow: none !important;
  border-color: var(--chakra-colors-brand-base);
  outline: none;
}

.queryBuilder select.rule-fields,
.queryBuilder select.rule-value,
.queryBuilder select.ruleGroup-combinators,
.queryBuilder select.rule-operators {
  padding: 0.3rem 1.5rem 0.3rem 0.3rem;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--chakra-colors-neutral-500) 50%
    ),
    linear-gradient(
      135deg,
      var(--chakra-colors-neutral-500) 50%,
      transparent 50%
    );
  background-position: calc(100% - 10px) calc(1em), calc(100% - 5px) calc(1em);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.current-model-in-dependency {
  box-shadow: 0 0 0 2px var(--chakra-colors-brand-base);
  animation: pulse-model-node 2s infinite;
}

@keyframes pulse-model-node {
  0% {
    box-shadow: 0 0 0 2px var(--chakra-colors-brand-200);
  }

  100% {
    box-shadow: 0 0 0 8px transparent;
  }
}

.current-workflow-node {
  border-radius: 0.5rem;
  box-shadow: 0 0 0 2px var(--chakra-colors-green-300);
  animation: pulse-workflow-node 2s infinite;
}

@keyframes pulse-workflow-node {
  0% {
    box-shadow: 0 0 0 2px var(--chakra-colors-green-300);
  }

  100% {
    box-shadow: 0 0 0 16px var(--chakra-colors-green-50);
  }
}

.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.hover-reveal {
  transition: opacity 1s ease-in-out;
}

.hover-reveal > * {
  opacity: 0;
}

.hover-reveal:hover > * {
  opacity: 1;
}

sup.beta {
  color: white;
  padding: 0 0.4rem;
  border-radius: 0.5rem;
  background: -webkit-linear-gradient(
    45deg,
    var(--chakra-colors-brand-base) 0%,
    var(--chakra-colors-purple-600) 100%
  );
}

/* Sprig is the product feedback feature that appears on the right side of the app */
#sprig-feedback-button {
  padding: 16px 4px 16px 4px !important;
  border: none;
  border-radius: 0;
  background-color: var(--chakra-colors-white) !important;
  font-weight: 600;
  color: var(--chakra-colors-brandSecondary-800);
  border: 1px solid var(--chakra-colors-brandSecondary-500);
  transition: all ease-in-out 0.2s !important;
}

#sprig-feedback-button:hover {
  background-color: var(--chakra-colors-brandSecondary-25) !important;
}

body.chakra-ui-dark #sprig-feedback-button {
  color: var(--chakra-colors-brandSecondary-100);
  background-color: var(--chakra-colors-brandSecondary-950) !important;
  border-color: var(--chakra-colors-brandSecondary-900);
}

body.chakra-ui-dark #sprig-feedback-button:hover {
  color: var(--chakra-colors-brandSecondary-25);
  background-color: var(--chakra-colors-brandSecondary-900) !important;
  border-color: var(--chakra-colors-brandSecondary-800);
}

body.reflex-col-resize {
  cursor: col-resize;
}

body.reflex-row-resize {
  cursor: row-resize;
}

.reflex-container {
  justify-content: flex-start;
  /* align items in Main Axis */
  align-items: stretch;
  /* align items in Cross Axis */
  align-content: stretch;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.reflex-container.horizontal {
  flex-direction: column;
  min-height: 1px;
}

.reflex-container.vertical {
  flex-direction: row;
  min-width: 1px;
}

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%;
}

.reflex-container > .reflex-splitter {
  background-color: #eeeeee;
  z-index: 100;
}

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: #c6c6c6;
  transition: all 1s ease;
}

.horizontal > .reflex-splitter {
  /* border-bottom: 1px solid #c6c6c6;
  border-top: 1px solid #c6c6c6;
  cursor: row-resize;
  width: 100%;
  height: 2px;  */
}

.reflex-element.horizontal .reflex-handle {
  cursor: grab;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
  cursor: col-resize;
  height: 100%;
  width: 2px;
}

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 100;
}

.reflex-container
  > .reflex-splitter.reflex-thin.active
  .reflex-container
  > .reflex-splitter.reflex-thin:hover {
  transition: all 1.5s ease;
  opacity: 0.5;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  border-top: 8px solid rgba(255, 255, 255, 0);
  height: 17px !important;
  cursor: row-resize;
  margin: -8px 0;
  width: 100%;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 8px solid #e4e4e4;
  border-top: 8px solid #e4e4e4;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 8px solid rgba(255, 255, 255, 0);
  border-left: 8px solid rgba(255, 255, 255, 0);
  width: 17px !important;
  cursor: col-resize;
  margin: 0 -8px;
  height: 100%;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 8px solid #e4e4e4;
  border-left: 8px solid #e4e4e4;
}
