.vis-timeline {
  border-radius: var(--chakra-radii-md);
  border-color: var(--chakra-colors-chakra-border-color);
  transition: box-shadow 0.3s ease-in-out;
}

.vis-active {
  box-shadow: 0 0 0 1px var(--chakra-colors-brand-base);
}

.vis-timeline.vis-active {
  cursor: grab !important;

}

.vis-timeline.vis-active:active {
  cursor: grabbing !important;
}

.vis-time-axis .vis-grid {
  background: var(--chakra-colors-neutral-25);
}
.vis-current-time {
  border-left: 2px dashed var(--chakra-colors-brand-base);
  background-color: transparent;
}

.vis-item {
  cursor: pointer;
  padding-left: var(--chakra-sizes-2);
  font-size: var(--chakra-fontSizes-sm);
  transition: background 0.3s ease-in-out;
}
.vis-item .vis-item-overflow {
  overflow: visible;
}
.vis-item:hover,
.vis-item.scheduled:hover,
.vis-item:not(.undefined-duration):hover,
.vis-item.undefined-duration:hover {
  background: var(--chakra-colors-brandSecondary-25) !important;
  color: var(--chakra-colors-brandSecondary-800);
  border-image: none !important;
  border: 1px solid;
  border-color: var(--chakra-colors-brandSecondary-600) !important;
}

.vis-item.undefined-duration {
  background: linear-gradient(to right, var(--chakra-colors-white), transparent);
  border-image: linear-gradient(
    to right,
    var(--chakra-colors-brandSecondary-600),
    transparent
  ) 1 !important;
}


.vis-item:not(.undefined-duration) {
  background-color: var(--chakra-colors-whiteAlpha-800) !important;
}


.vis-item.active {
  border: 1px solid;
  border-color: var(--chakra-colors-brandSecondary-600);
  color: var(--chakra-colors-brandSecondary-800) !important;
}

.vis-item.scheduled {
  border-image: linear-gradient(
    to right,
    var(--chakra-colors-neutral-300),
    transparent
  ) 1 !important;
  color: var(--chakra-colors-neutral-500);
}



.vis-item.delayed {
  background-color: #FDFEFE;
  border-color: #B0241C;
  color: #B0241C;
}



.vis-time-axis {
  background-color: var(--chakra-colors-neutral-100);
}
.vis-time-axis .vis-text {
  font-weight: bold;
}

.vis-item .vis-item-content div {
}

.vis-item .vis-item-content div span {
}


.vis-item.delayed .vis-item-content div span:nth-child(2) {
  background-color: var(--chakra-colors-red-500);
  padding: 0 0.25rem;
  border-radius: 0.2rem;
  color: white;
  font-weight: bold;
}

.vis-item .vis-item-content div span:last-child {
  background-color: transparent;

}
.vis-item.vis-range {
  border-radius: 0;
}

.chakra-ui-dark .vis-time-axis .vis-grid {
  background: var(--chakra-colors-neutral-950);
  border: 1px solid var(--chakra-colors-neutral-850);
}

.chakra-ui-dark .vis-time-axis .vis-text {
  color: var(--chakra-colors-neutral-300);
}

.chakra-ui-dark .vis-item  {
  color: var(--chakra-colors-brandSecondary-100) !important;
}

.chakra-ui-dark .vis-item:hover,
.chakra-ui-dark .vis-item.undefined-duration:hover,
.chakra-ui-dark .vis-item:not(.undefined-duration):hover {
  background: var(--chakra-colors-brandSecondary-900) !important;
  color: var(--chakra-colors-brandSecondary-25) !important;
  border-color: var(--chakra-colors-brandSecondary-800) !important;
}

.chakra-ui-dark .vis-item.undefined-duration {
  background: linear-gradient(to right, var(--chakra-colors-brandSecondary-950), transparent);
  border-image: linear-gradient(
    to right,
    var(--chakra-colors-brandSecondary-600),
    transparent
  ) 1 !important;
}

.chakra-ui-dark .vis-time-axis  {
  background:var(--chakra-colors-neutral-850);
  color: var(--chakra-colors-white);
}

.chakra-ui-dark .vis-item:not(.undefined-duration) {
  background-color: var(--chakra-colors-brandSecondary-950) !important;
}
